import React from 'react';
import { useUser } from '@/context/UserProvider';
import { getPersonName, getShortcut } from '@/types/Person';
import { type Size } from '../client/ClientIconLink';
import clsx from 'clsx';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { type Id } from '@/types/Id';
import { isColorDark } from '@/utils/common';

type TeamMemberBadgeProps = Readonly<{
    appUserId?: Id;
    className?: string;
    size?: Size;
}>;

export function TeamMemberBadge({ appUserId, className, size }: TeamMemberBadgeProps) {
    const { teamMembers } = useUser();
    if (!appUserId)
        return null;

    const member = teamMembers.getOtherByAppUserId(appUserId);
    if (!member)
        return null;

    return (
        <OverlayTrigger
            overlay={<Tooltip>{getPersonName(member)}</Tooltip>}
        >
            <div className='w-fit'>
                <TeamMemberIconCustom text={getShortcut(member)} color={member.color} className={className} size={size} />
            </div>
        </OverlayTrigger>
    );
}

type TeamMemberIconCustomProps = Readonly<{
    text: string;
    color: string;
    className?: string;
    size?: Size;
}>;

function TeamMemberIconCustom({ text, color, className, size }: TeamMemberIconCustomProps) {
    return (
        <div {...getSettings(color, className, size)}>
            <span>{text}</span>
        </div>
    );
}

const settingsBySize: Record<Size, { className: string, style: React.CSSProperties }> = {
    sm: {
        className: 'flex-shrink-0 d-flex align-items-center justify-content-center unselectable lha-1 fw-medium',
        style: { width: 20, height: 18, padding: 2, borderRadius: 4, fontSize: 10 },
    },
    md: {
        className: 'flex-shrink-0 d-flex align-items-center justify-content-center unselectable lha-2 fw-medium',
        style: { width: 24, height: 24, padding: 2, borderRadius: 4, fontSize: 10 },
    },
    lg: {
        className: 'flex-shrink-0 d-flex align-items-center justify-content-center unselectable lha-2 fw-medium fs-6',
        style: { width: 40, height: 40, padding: 2, borderRadius: 4, fontSize: 12 },
    },
};

function getSettings(color: string, className?: string, size?: Size) {
    const settings = settingsBySize[size ?? 'sm'];
    const textColor = isColorDark(color) ? 'text-white' : 'text-dark';

    return {
        className: clsx(settings.className, textColor, className),
        style: { ...settings.style, backgroundColor: `#${color}` },
    };
}
