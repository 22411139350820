import React, { useCallback, useEffect, useMemo, useState, type ReactNode } from 'react';
import { api } from '@/utils/api/backend';
import { useNavigate, useParams } from 'react-router-dom';
import { Event } from '@/types/Event';
import { createActionState, useClients, useLocations } from '@/hooks';
import type { PreselectEvent, Selected } from '../CalendarDetail';
import { routes } from '@/router';
import { EventDisplayInner } from '@/components/calendar/EventSidebar';
import { useEvent, type UpdateEventsAction } from '@/components/event/useEvent';
import { Container } from 'react-bootstrap';
import { type ClientInfo } from '@/types/Client';
import { type BaseLocation } from '@/types/location';
import { eventToCalendarEvent } from '@/types/calendar/Calendar';
import clsx from 'clsx';
import { useUser } from '@/context/UserProvider';

// TODO this component doesn't work properly yet. The event can't be saved and we are still waiting for a new design.
// Therefore, all event-detail requests should be redirected to the calendar for now.

export default function EventDetail() {
    const { id } = useParams();
    const [ event, setEvent ] = useState<Event>();
    const { clients, addClients } = useClients();
    const { locations, addLocation } = useLocations();
    const { teamMembers } = useUser();

    const fetchEvent = useCallback(async (signal: AbortSignal) => {
        if (!id)
            return;

        const response = await api.event.get({ id }, signal);
        if (!response.status)
            return;

        const fetchedEvent = Event.fromServer(response.data);
        setEvent(fetchedEvent);
    }, [ id ]);

    useEffect(() => {
        const [ signal, abort ] = api.prepareAbort();
        fetchEvent(signal);

        return abort;
    }, [ fetchEvent ]);

    const navigate = useNavigate();

    const updateEvents = useCallback((action?: UpdateEventsAction) => {
        if (!action)
            return;

        const newEvent = action.events.find(e => e.id.toString() === id);
        if (!newEvent)
            return;

        if (action.type === 'delete')
            navigate(routes.events.list);

        setEvent(newEvent);
    }, [ id, navigate ]);

    const input = useMemo(() => event ? { event: eventToCalendarEvent(event, teamMembers) } : undefined, [ event, teamMembers ]);

    if (!input || !clients || !locations)
        return null;

    return (
        <EventDetailInner
            input={input}
            onEventsUpdate={updateEvents}
            clients={clients}
            addClients={addClients}
            locations={locations}
            addLocation={addLocation}
        />
    );
}

type EventDetailInnerProps = Readonly<{
    input: Selected;
    onEventsUpdate: (action?: UpdateEventsAction) => void;
    clients: ClientInfo[];
    addClients: (clients: ClientInfo[]) => void;
    locations: BaseLocation[];
    addLocation: (location: BaseLocation) => void;
}>;

function EventDetailInner({ input, onEventsUpdate, addClients, ...rest }: EventDetailInnerProps) {
    const { state, dispatch } = useEvent(input, onEventsUpdate, addClients);

    return (
        <Container className='content-large d-flex flex-column'>
            <EventDisplayInner
                state={state}
                dispatch={dispatch}
                {...rest}
            />
        </Container>
    );
}

type EventLinkProps = Readonly<{
    event: Event;
    className?: string;
    children: ReactNode;
}>;

// export function EventDetailLink({ event, className, children }: EventLinkProps) {
export function EventDetailLink(props: EventLinkProps) {
    // TODO temporary fix until we redesign the detail.
    return EventInCalendarLink(props);

    // return (
    //     // TODO temporary fix until we can transfer state via Link (probably by url)
    //     // <Link to={routes.calendar} state={createActionState<PreselectEvent>('preselectEvent', event.id.toIRI())} className={className}>
    //     <Link to={routes.events.detail.resolve({ id: event.id.toString() })} className={className}>
    //         {children}
    //     </Link>
    // );
}

export function EventInCalendarLink({ event, className, children }: EventLinkProps) {
    const navigate = useNavigate();

    return (
        // TODO temporary fix until we can transfer state via Link (probably by url)
        // <Link to={routes.calendar} state={createActionState<PreselectEvent>('preselectEvent', event.id.toIRI())} className={className}>
        <div
            onClick={() => navigate(routes.calendar, { state: createActionState<PreselectEvent>('preselectEvent', event.id.toIRI()) })}
            className={clsx(className, 'todo-link-fix text-truncate clickable')}
        >
            {children}
        </div>
    );
}
