import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { api } from '@/utils/api/backend';
import { type Event } from '@/types/Event';
import Calendar, { DEFAULT_CALENDAR_VIEW, type EventChangeArgs } from '@/components/calendar/Calendar';
import { useTranslation } from 'react-i18next';
import { type CalendarEvent, eventToCalendarEvent, type FullEventResource, isFullEvent, isDraftEvent, type DraftEventResource, createDraftEvent, isGoogleEvent, eventsToCalendarEvents } from '@/types/calendar/Calendar';
import EventSidebar from '@/components/calendar/EventSidebar';
import { useNavigationAction, useGoogleCalendars, useMonthLoader, type NavigationProperty, IdFilter, groupMonthFunction, type GroupParams, type SimpleParams, simpleMonthFunction, fetchFlowlanceEvents, LoadingState, useClients } from '@/hooks';
import { googleEventsToCalendarEvents, fetchGoogleEvents, googleEventToServer, type GoogleEvent } from '@/types/calendar/Google';
import { EventGroup } from '@/types/EventGroup';
import type { SlotInfo } from '@/lib/calendar';
import GoogleCalendarsDisplay from '@/components/calendar/GoogleCalendarsDisplay';
import { toMaster, useUser } from '@/context/UserProvider';
import { GoogleIntegrationBadge } from '@/components/integrations/GoogleIntegration';
import { Button } from 'react-bootstrap';
import { CalendarPlusIcon } from '@/components/icons';
import { Id, type IRI } from '@/types/Id';
import { VisibleEventsSummary } from '@/components/calendar/VisibleEventsSummary';
import { DateTime } from 'luxon';
import type { View } from '@/lib/calendar/Views';
import { minutesToSeconds, roundDateToMinutes } from '@/utils/common';
import { createNewClients, type UpdateEventsAction } from '@/components/event/useEvent';
import { useAnalytics } from '@/types/analytics';
import { TaskList } from '@/components/task/TaskList';
import { DEFAULT_PRICING_DURATION_IN_MINUTES } from '@/components/calendar/PricingsEditor';
import { UserRole } from '@/types/Team';
import { TeamMembersCalendarFilter, useTeamMembersCalendarFilter } from '@/components/event/TeamMembersCalendarFilter';
import { isGoogleCalendarEnabled } from '@/types/AppUser';

export type PreselectEvent = NavigationProperty<'preselectEvent', 'new' | IRI>;

type SelectedEvent = {
    event: CalendarEvent<FullEventResource>;
    changes?: InitialEventChanges;
}

type InitialEventChanges = {
    start: DateTime;
    end: DateTime;
};

export type Selected = SelectedEvent | {
    draft: CalendarEvent<DraftEventResource>;
}

export function getSelectedEvent(selected: Selected | undefined): SelectedEvent | undefined {
    return (selected && 'event' in selected) ? selected : undefined;
}

export default function CalendarDetail() {
    const { t } = useTranslation('pages', { keyPrefix: 'calendar' });
    const userContext = useUser();
    const { appUser, settings, googleUserInfo, role } = userContext;
    const isMaster = role === UserRole.Master;
    const defaultEventDuration = toMaster(userContext)?.teamSettings.pricings[0].duration ?? minutesToSeconds(DEFAULT_PRICING_DURATION_IN_MINUTES);

    const preselectEvent = useNavigationAction<PreselectEvent>('preselectEvent');
    const [ date, setDate ] = useState<DateTime>(() => DateTime.now());
    const [ view, setView ] = useState<View>(DEFAULT_CALENDAR_VIEW);
    const loadingGoogleEvents = useRef<Set<string>>(new Set());

    // Flowlance events

    const flowlanceMonthFunction = useCallback((params: SimpleParams) => simpleMonthFunction(
        fetchFlowlanceEvents,
        params,
    ), []);

    const flowlanceIdFilter = useMemo(() => new IdFilter((event: Event) => event.id.toString()), []);

    const { dataObject, setDataObject, handleNavigate, visibleMonths } = useMonthLoader(flowlanceMonthFunction, flowlanceIdFilter);

    // For now, we filter the team members only on FE.
    // TODO do it on BE
    const { disabledIds: disabledMemberIds, toggleMember } = useTeamMembersCalendarFilter();

    // Google events

    const { calendars, activeIds, toggleCalendar } = useGoogleCalendars();

    const googleMonthFunction = useCallback((params: GroupParams) => groupMonthFunction(
        (start: DateTime, end: DateTime, element: string, signal?: AbortSignal) => fetchGoogleEvents(start, end, element, signal),
        [ ...activeIds.values() ],
        params,
    ), [ activeIds ]);

    const googleIdFilter = useMemo(() => new IdFilter((event: GoogleEvent) => event.id), []);

    const {
        dataObject: googleDataObject,
        handleNavigate: googleHandleNavigate,
        visibleMonths: googleVisibleMonths,
    } = useMonthLoader(googleMonthFunction, googleIdFilter);

    // Loading spinner

    const visibleMonthsLoaded = useMemo(() => {
        const flowlanceLoaded = visibleMonths.every(month => month === LoadingState.Loaded);
        return flowlanceLoaded && !!calendars && (
            !isGoogleCalendarEnabled(appUser, role)
            || googleVisibleMonths.every(month => !month || Object.entries(month).every(([ id, state ]) => !activeIds.has(id) || state === LoadingState.Loaded))
        );
    }, [ visibleMonths, googleVisibleMonths, calendars, activeIds, appUser ]);

    // Selecting, creating and editing events

    const [ selected, setSelected ] = useState<Selected>();

    const clonedGoogleEvents = useMemo(() => {
        return dataObject.data.filter(ev => !!ev.googleId);
    }, [ dataObject ]);

    const { teamMembers } = userContext;
    const calendarEvents = useMemo(() => calendars ? eventsToCalendarEvents(dataObject.data, teamMembers, disabledMemberIds, calendars, activeIds, appUser.googleCalendarId) : [], [ dataObject, teamMembers, disabledMemberIds, calendars, activeIds, appUser.googleCalendarId ]);

    const events = useMemo(() => [
        ...calendarEvents,
        ...(calendars ? googleEventsToCalendarEvents(googleDataObject.data, calendars, activeIds) : []),
        ...(selected && 'draft' in selected ? [ selected.draft ] : []),
    ].flatMap(event => {
        if (!isFullEvent(event)) {
            // Don't show Google events that are already in our db
            if (isGoogleEvent(event)) {
                if (visibleMonthsLoaded) {
                    const flowlanceEvent = clonedGoogleEvents.find(ev => ev.googleId === event.resource.event.id);
                    if (!flowlanceEvent)
                        return event;
                }

                return [];
            }
            return event;
        }

        // ?
        const selectedEvent = getSelectedEvent(selected);
        if (selectedEvent?.event.resource.event.id.equals(event.resource.event.id))
            return selectedEvent.event;

        // the current flowlance event could be a Google event clone
        // we first need to load google events, so we know the correct color
        if (!visibleMonthsLoaded)
            return [];

        return event;
    }), [ calendarEvents, googleDataObject, selected, calendars, activeIds, clonedGoogleEvents, visibleMonthsLoaded ]);

    const updateEvents = useCallback((action: UpdateEventsAction) => {
        setDataObject(({ data }) => ({ data: computeEventsUpdate(data, action) }));
    }, [ setDataObject ]);

    useEffect(() => {
        if (getSelectedEvent(selected) || !preselectEvent?.data)
            return;

        if (preselectEvent.data === 'new') {
            handleNewEventButton();
            return;
        }

        const preselectedEventId = Id.fromIRI(preselectEvent.data);
        const preselectedEvent = calendarEvents.find(event => event.resource.event.id.equals(preselectedEventId));
        if (preselectedEvent) {
            setSelected({ event: preselectedEvent });
            setDate(preselectedEvent.start);
        }
    }, [ calendarEvents, preselectEvent ]);

    useEffect(() => {
        const selectedEvent = getSelectedEvent(selected);
        if (!selectedEvent)
            return;

        const newSelectedEvent = calendarEvents.find(event => event.resource.event.id.equals(selectedEvent.event.resource.event.id));
        if (!newSelectedEvent) {
            setSelected(undefined);
            return;
        }

        if (newSelectedEvent !== selectedEvent.event) {
            setSelected({
                event: {
                    ...newSelectedEvent,
                    ...selectedEvent.changes,
                },
                changes: selectedEvent.changes,
            });
        }
        // This is intentional - we want to update the selected event only when the original events change.
        // TODO - do this in a way that doesn't require dependency on the selectedEvent.
    }, [ calendarEvents ]);

    const handleNewEventButton = useCallback(() => {
        const startDate = roundDateToMinutes(DateTime.now(), 15);
        const draft = createDraftEvent(startDate, startDate.plus({ second: defaultEventDuration }), t('new-event'));
        setSelected({ draft });
    }, [ defaultEventDuration, t ]);

    const handleSelectSlot = useCallback(({ start, end, action }: SlotInfo) => {
        if (action === 'click')
            end = start.plus({ second: defaultEventDuration });
        const draft = createDraftEvent(start, end, t('new-event'));
        setSelected({ draft });
    }, [ defaultEventDuration, t ]);

    const rescheduleEvent = useCallback(({ event, start, end }: EventChangeArgs) => {
        // `event` is the event currently stored in `events` (we want to change that one)
        // `start`, `end` and `isAllDay` is the information about the change that happened

        const changed: CalendarEvent = {
            ...event,
            start,
            end,
        };

        if (isDraftEvent(changed)) {
            setSelected({ draft: changed });
        }
        else if (isFullEvent(changed)) {
            setSelected({
                event: changed,
                changes: {
                    start,
                    end,
                },
            });
        }
    }, []);

    const closeEventSidebar = useCallback((action?: UpdateEventsAction) => {
        setSelected(undefined);
        if (action)
            updateEvents(action);
    }, [ updateEvents ]);

    const { clients, addClients } = useClients();
    const analytics = useAnalytics();

    async function createEventFromGoogle(googleEvent: GoogleEvent) {
        if (!googleUserInfo)
            return;  // oh no

        loadingGoogleEvents.current.add(googleEvent.id);

        const init = googleEventToServer(googleEvent, settings, clients ?? [], googleUserInfo, appUser);
        const response = await api.event.createFromGoogle(init);
        if (!response.status)
            return;

        const newEventGroup = EventGroup.fromServer(response.data);
        analytics.eventGroupCreated(newEventGroup, 'google');

        const newEvents = newEventGroup.events;
        const events = newEvents.map(e => eventToCalendarEvent(e, teamMembers));

        const newClients = createNewClients(init.newParticipants.map(p => p.contact), newEventGroup, analytics);
        addClients(newClients);

        updateEvents({ type: 'update', events: newEvents });
        // TODO Don't know if this is enough, because the CalendarEvent will be also created in the useMemo above.
        setSelected({ event: events[0] });
        loadingGoogleEvents.current.delete(googleEvent.id);
        flowlanceIdFilter.apply(newEvents);
    }

    async function selectEvent(event: CalendarEvent) {
        if (isFullEvent(event)) {
            setSelected({ event });
            return;
        }

        if (!isGoogleEvent(event) || event.allDay || loadingGoogleEvents.current.has(event.resource.event.id))
            return;

        await createEventFromGoogle(event.resource.event);
    }

    return (
        <div className='d-flex h-100'>
            <EventSidebar
                input={selected}
                onClose={closeEventSidebar}
            />
            <div className='flex-grow-1 min-w-0'>
                <Calendar
                    events={events}
                    selected={selected}
                    onSelectEvent={selectEvent}
                    onNavigate={(newDate, view) => {
                        setDate(newDate);
                        handleNavigate(newDate, view);
                        googleHandleNavigate(newDate, view);
                    }}
                    view={view}
                    onView={setView}
                    onSelectSlot={handleSelectSlot}
                    onMoveEvent={rescheduleEvent}
                    onResizeEvent={rescheduleEvent}
                    loading={!visibleMonthsLoaded}
                    date={date}
                    clients={clients ?? []}
                />
            </div>
            <div className='sh-calendar-sidebar p-3 sh-main-scroller sh-main-scroller-no-center'>
                <Button className='sh-full-button w-100' onClick={handleNewEventButton} variant='primary'>
                    <CalendarPlusIcon size={22} className='me-2' />
                    {t('new-event-button')}
                </Button>
                <VisibleEventsSummary events={events} date={date} view={view} className='mt-3' />
                <TaskList />
                {isMaster ? (<>
                    <h3 className='mt-4 mb-3'>{t('team-members-title')}</h3>
                    <TeamMembersCalendarFilter disabledIds={disabledMemberIds} toggleMember={toggleMember} />
                </>) : (<>
                    <h3 className='mt-4 mb-3'>{t('calendars-title')}</h3>
                    <div className='mb-3'>
                        <GoogleIntegrationBadge />
                    </div>
                    {appUser.isCalendarEnabled && (<>
                        {calendars && <GoogleCalendarsDisplay calendars={calendars} activeIds={activeIds} toggleCalendar={toggleCalendar} />}
                    </>)}
                </>)}
            </div>
        </div>
    );
}

function computeEventsUpdate(originalEvents: Event[], { type, events }: UpdateEventsAction): Event[] {
    if (type === 'create')
        return [ ...originalEvents, ...events ];

    const filteredEvents = originalEvents.filter(e => !events.find(event => event.id.equals(e.id)));

    return type === 'delete'
        ? filteredEvents
        : [ ...filteredEvents, ...events ];
}
