import clsx from 'clsx';
import { type CalendarEvent, getEventSize, isGoogleEvent, isDraftEvent, isFullEvent } from '@/types/calendar/Calendar';
import { googleEventIsTransparent } from '@/types/calendar/Google';
import { eventIsTransparent } from '@/types/Event';
import { DateTime } from 'luxon';
import type { CSSProperties } from 'react';
import { isColorDark } from '@/utils/common';

export function getEventStyle(event: CalendarEvent, showMembers: boolean, selected?: boolean): { className?: string, style?: CSSProperties } {
    if (isDraftEvent(event)) {
        return {
            className: `draft sh-event-lines-${getEventSize(event)}`,
        };
    }

    if (isGoogleEvent(event)) {
        return {
            className: clsx('google', `sh-event-lines-${getEventSize(event)}`, {
                transparent: googleEventIsTransparent(event.resource.event),
                past: event.end <= DateTime.now(),
                'non-clickable': event.allDay,
            }),
            style: {
                '--sh-event-base-color': event.resource.calendar.color,
            },
        };
    }

    if (isFullEvent(event)) {
        const className = clsx(`sh-event-lines-${getEventSize(event)}`, {
            transparent: eventIsTransparent(event.resource.event),
            past: event.end <= DateTime.now(),
            tooBright: showMembers && !isColorDark(event.resource.teamMember.color),
        });

        const baseColor = showMembers
            ? '#' + event.resource.teamMember.color
            : event.resource.calendar?.color;

        return {
            className,
            style: baseColor ? {
                '--sh-event-base-color': baseColor,
            } : {},
        };

    }

    return {};
}
