import React, { type CSSProperties } from 'react';
import clsx from 'clsx';
import EventWrapper from './addons/dragAndDrop/EventWrapper';
import { MoneyDisplay } from '@/components/common';
import { getEventStyle } from './eventStyle';
import type { CalendarEvent } from '@/types/calendar/Calendar';
import { useUser } from '@/context/UserProvider';
import { rightToLeft } from './utils/common';
import { UserRole } from '@/types/Team';

type TimeGridEventProps = {
    event: CalendarEvent & { __isPreview?: boolean };
    selected: boolean;
    continuesPrior: boolean;
    continuesAfter: boolean;
    onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
    onDoubleClick: (e: React.MouseEvent<HTMLDivElement>) => void;
    onKeyPress: (e: React.KeyboardEvent<HTMLDivElement>) => void;
    style: CSSProperties & {
        height: string | number;
        top: string | number;
        width: string | number;
        xOffset: number;
    };
    className?: string;
    label: string;
    isBackgroundEvent?: boolean;
};

export default function TimeGridEvent(props: TimeGridEventProps) {
    const { style, className, event, selected, label, continuesPrior, continuesAfter, onClick, onDoubleClick, isBackgroundEvent, onKeyPress } = props;
    const tooltip = event.tooltip ?? event.title;

    const isMaster = useUser().role === UserRole.Master;
    const userProps = getEventStyle(event, isMaster, selected);
    const { height, top, width, xOffset } = style;
    const eventStyle = isBackgroundEvent
        ? {
            ...userProps.style,
            top: stringifyPercent(top),
            height: stringifyPercent(height),
            // Adding 10px to take events container right margin into account
            width: `calc(${width} + 10px)`,
            [rightToLeft ? 'right' : 'left']: stringifyPercent(Math.max(0, xOffset)),
        }
        : {
            ...userProps.style,
            top: stringifyPercent(top),
            width: stringifyPercent(width),
            height: stringifyPercent(height),
            [rightToLeft ? 'right' : 'left']: stringifyPercent(xOffset),
        };

    return (
        <EventWrapper type='time' {...props}>
            <div
                onClick={onClick}
                onDoubleClick={onDoubleClick}
                style={eventStyle}
                onKeyPress={onKeyPress}
                title={
                    tooltip
                        ? (typeof label === 'string' ? label + ': ' : '') + tooltip
                        : undefined
                }
                className={clsx(
                    isBackgroundEvent ? 'rbc-background-event' : 'rbc-event',
                    className,
                    userProps.className,
                    {
                        'rbc-selected': selected,
                        'rbc-event-continues-earlier': continuesPrior,
                        'rbc-event-continues-later': continuesAfter,
                    },
                )}
            >
                <div className='rbc-event-label'>
                    {label}
                </div>
                <div className='rbc-event-content'>
                    <EventTitle event={event} />
                </div>
            </div>
        </EventWrapper>
    );
}

function stringifyPercent(value: string | number) {
    return typeof value === 'string' ? value : value + '%';
}

type EventTitleProps = Readonly<{
    event: CalendarEvent;
}>;

export function EventTitle(props: EventTitleProps) {
    const { settings } = useUser();

    if (props.event.resource.type !== 'event')
        return props.event.title;

    const { event, totalPrice } = props.event.resource;

    return (<>
        {!settings.hideEventPrices && totalPrice && (
            <span className='text-nowrap fw-medium'>(<MoneyDisplay money={totalPrice} />){' '}</span>
        )}
        {event.isInPackage ? (
            <span className='text-nowrap fw-medium'>{event.packageProgress}{' '}</span>
        ) : event.recurrenceIndex !== undefined ? (
            <span className='text-nowrap fw-medium'>#{event.recurrenceIndex + 1}{' '}</span>
        ) : null}
        {event.title}
    </>);
}
